import { useEffect } from 'react'
import { navigate } from 'gatsby'

const RedirectComponent = ({ fromPath, toPath }) => {
  useEffect(() => {
    navigate(`/${toPath}`);
  }, [toPath]);

  return null
}

export default RedirectComponent