import React from 'react'
import RedirectComponent from '../components/Redirect'

class Redirect extends React.Component {
  render() {
    return (
      <RedirectComponent
        fromPath={this.props.pageContext.fromPath}
        toPath={this.props.pageContext.toPath}
      />
    );
  }
}

export default Redirect